import techgurusk from './design/techgurusk.jpg';
import techguruskExample1 from './design/techguru-example1.png';
import techguruskExample2 from './design/techguru-example2.png';
import techguruskExample4 from './design/techguru-example4.jpg';
import techguruskExample5 from './design/techguru-example5.jpg';
import techguruskExample6 from './design/techguru-example6.jpg';
import techguruskExample7 from './design/techguru-example7.jpg';
import techguruskExample8 from './design/techguru-example8.jpg';
import techguruskExample9 from './design/techguru-example9.jpg';
import techguruskExample10 from './design/techguru-example10.png';
import techguruskExample11 from './design/techguru-example11.png';
import techguruskExample12 from './design/techguru-example12.png';

import octosign from './design/octosign.png';
import octosignExample1 from './design/octosign-example1.png';
import octosignExample2 from './design/octosign-example2.png';
import octosignExample3 from './design/octosign-example3.png';

import pixea from './design/pixea.png';
import pixeaExample1 from './design/pixea-example1.png';
import pixeaExample2 from './design/pixea-example2.png';
import pixeaExample3 from './design/pixea-example3.png';
import pixeaExample4 from './design/pixea-example4.png';
import pixeaExample5 from './design/pixea-example5.png';
import pixeaExample6 from './design/pixea-example6.png';

import baronprosimir from './design/baronprosimir.png';
import chudakvsechno from './design/chudakvsechno.png';
import hey from './design/hey.png';
import hydrokon from './web/hydrokon.png';
import gymgir2021 from './film/gymgir2021.png';
import zsgir2021 from './film/zsgir2021.png';
import vamarhan2019 from './film/vamarhan2019.png';
import vaszsgir2019 from './film/vaszsgir2019.png';
import area from './other/area.png';

import itpodnik from './design/itpodnik.png';
import itpodnikExample1 from './design/itpodnik-example1.png';
import itpodnikExample2 from './design/itpodnik-example2.png';

import bakstol from './design/bakstol.png';
import basktolExample1 from './design/bakstol-example1.png';
import bakstolExample2 from './design/bakstol-example2.png';
import bakstolExample3 from './design/bakstol-example3.png';

import jandurasme from './web/jandurasme.png';

const projects = [
  {
    key: 'gymgir2021',
    name: 'Gymnázium Giraltovce 2021',
    category: 'Filmmaking',
    content: 'Short video for Gymnázium Giraltovce to showcase and promote the school to future students involving both shooting and editing.',
    linkUrl: 'https://www.youtube.com/watch?v=YrGT-Uk4p_4',
    linkText: 'Check it out at YouTube.com',
    skills: ['Filmmaking', 'Video editing', 'Sound design'],
    work: [],
    date: 'February 2021',
    src: gymgir2021,
  },
  {
    key: 'zsgir2021',
    name: 'Základná škola Giraltovce 2021',
    category: 'Filmmaking',
    content: 'Short video for Základná škola Giraltovce to showcase and promote the school to future students involving both shooting and editing.',
    linkUrl: 'https://www.youtube.com/watch?v=FzGy9FEK2IE',
    linkText: 'Check it out at YouTube.com',
    skills: ['Filmmaking', 'Video editing', 'Sound design'],
    work: [],
    date: 'March 2021',
    src: zsgir2021,
  },
  {
    key: 'vamarhan2019',
    name: 'Christmas academy of primary school in Marhaň 2019',
    category: 'Filmmaking',
    content: 'Video for ZŠ Marhaň capturing their traditional Christmas academy involving shooting using multiple cameras, editing while also taking care of the whole production.',
    linkUrl: 'https://www.youtube.com/watch?v=r6N4zvtD2XI',
    linkText: 'Check it out at YouTube.com',
    skills: ['Filmmaking', 'Video editing', 'Sound design'],
    work: [],
    date: 'December 2019',
    src: vamarhan2019,
  },
  {
    key: 'vaszsgir2019',
    name: 'Christmas academy of private primary school in Giraltovce',
    category: 'Filmmaking',
    content: 'Several videos for SZŠ Giraltovce capturing their traditional Christmas academy involving shooting using multiple cameras, editing while also taking care of the whole production.',
    linkUrl: '',
    linkText: '',
    skills: ['Filmmaking', 'Video editing', 'Sound design'],
    work: [],
    date: 'December 2017 - December 2019',
    src: vaszsgir2019,
  },
  {
    key: 'hey',
    name: 'School magazine HEY!',
    category: 'Design',
    content: 'Design of several magazines called HEY! for the private primary school in Giraltovce.',
    linkUrl: 'https://joom.ag/NE6e/',
    linkText: 'Check out the last one I did here',
    skills: ['Graphic design', 'Illustration', 'Editorial design'],
    work: [],
    date: '2016 - 2019',
    src: hey,
  },
  {
    key: 'hydrokon',
    name: 'Hydrokon.sk',
    category: 'Web development',
    content: 'Simple website running purely on Boostrap for Hydrokon - service of UNC, LOCUST, GEHL and MANITOU loaders.',
    linkUrl: 'https://hydrokon.sk/',
    linkText: 'Check it out at Hydrokon.sk',
    skills: ['Bootstrap', 'Web development'],
    work: [],
    date: 'October 2019',
    src: hydrokon,
  },
  {
    key: 'techgurusk',
    name: 'TechGuru.sk',
    category: 'Design',
    content: 'Being in the project since its beginning and doing lots of filmmaking, web development, web design, branding as well as other design related stuff for TechGuru.sk - a Slovak portal that brings product reviews and news from the world of technology and gaming.',
    linkUrl: 'https://techguru.sk/',
    linkText: 'Check them out at TechGuru.sk',
    skills: ['Graphic Design', 'Logo', 'Branding', 'Filmmaking', 'Video editing', 'Sound design', 'Web design', 'Web development', 'WordPress'],
    work: [techguruskExample1, techguruskExample2, techguruskExample4, techguruskExample5, techguruskExample6, techguruskExample7, techguruskExample8, techguruskExample9, techguruskExample10, techguruskExample11, techguruskExample12],
    date: 'July 2017',
    src: techgurusk,
  },
  {
    key: 'pixea',
    name: 'pixea',
    category: 'Design',
    content: 'Logo, branding as well as landing page and system design for pixea - Slovak printing company.',
    linkUrl: 'https://pixea.sk/',
    linkText: 'Check them out at pixea.sk',
    skills: ['Graphic design', 'Logo', 'Branding', 'Web design', 'UI design'],
    work: [pixeaExample1, pixeaExample2, pixeaExample3, pixeaExample4, pixeaExample5, pixeaExample6],
    date: 'November 2018',
    src: pixea,
  },
  {
    key: 'octosign',
    name: 'Octosign',
    category: 'Design',
    content: 'Logo, branding as well as application design for Octosign - open-source digital signature software.',
    linkUrl: 'https://octosign.com/',
    linkText: 'Check it out at Octosign.com',
    skills: ['Graphic design', 'Logo', 'Branding', 'UI design'],
    work: [octosignExample1, octosignExample2, octosignExample3],
    date: 'September 2019',
    src: octosign,
  },
  {
    key: 'baronprosimir',
    name: 'BaronProsimir',
    category: 'Design',
    content: 'Logo and branding for BaronProsimir - gamer and streamer.',
    linkUrl: 'https://www.twitch.tv/baronprosimir',
    linkText: 'Check him out at Twitch.tv',
    skills: ['Graphic design', 'Logo', 'Branding'],
    work: [],
    date: 'May 2020',
    src: baronprosimir,
  },
  {
    key: 'chudakvsechno',
    name: 'Chudák všechno',
    category: 'Design',
    content: 'Cover for Facebook group called "Chudák všechno".',
    linkUrl: 'https://www.facebook.com/groups/chudakvsechno',
    linkText: 'Check them out at Facebook.com',
    skills: ['Graphic design', 'Illustration'],
    work: [],
    date: 'March 2020',
    src: chudakvsechno,
  },
  {
    key: 'area',
    name: 'AREA',
    category: 'Other',
    content: 'Little bit of designing, video editing and a simple website for a project called AREA.',
    linkUrl: '',
    linkText: '',
    skills: ['Graphic design', 'Video editing', 'Web development'],
    work: [],
    date: 'January 2019',
    src: area,
  },
  {
    key: 'itpodnik',
    name: 'ITPODNIK',
    category: 'Design',
    content: 'Web design for ITPODNIK.cz.',
    linkUrl: '',
    linkText: '',
    skills: ['Web design'],
    work: [itpodnik, itpodnikExample1, itpodnikExample2],
    date: 'March 2018',
    src: itpodnik,
  },
  {
    key: 'bakstol',
    name: 'Bakstol',
    category: 'Design',
    content: 'Web design for Bakstol, s.r.o. - firm doing carpentry and woodwork.',
    linkUrl: '',
    linkText: '',
    skills: ['Web design'],
    work: [bakstol, basktolExample1, bakstolExample2, bakstolExample3],
    date: 'January 2019',
    src: bakstol,
  },
  {
    key: 'jandurasme',
    name: 'This portfolio',
    category: 'Web development',
    content: 'This very portfolio you are looking at right now, built from scratch and running using modern technologies like Gatsby and React.',
    linkUrl: '',
    linkText: '',
    skills: ['Web design', 'Web development', 'React', 'Gatsby', 'JavaScript', 'Tailwind CSS'],
    work: [],
    date: 'March 2021',
    src: jandurasme,
  },
];

export default projects;
